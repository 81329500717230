import gql from 'graphql-tag'
import ProjectAllFields from './fragments/ProjectAllFields'

export default gql`
  query getProjects ($slug: String!, $excludeDraft: Boolean) {
    projects (slug: $slug, excludeDraft: $excludeDraft) {
      ...ProjectAllFields
    }
  }
  ${ProjectAllFields}
`
