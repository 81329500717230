<template>
  <v-container fluid>
    <v-overlay
      v-if="$apollo.queries.event.loading || $apollo.queries.projects.loading || $apollo.queries.comments.loading"
      color="white"
      opacity="1"
      absolute
    >
      <v-progress-circular
        :size="64"
        color="primary"
        indeterminate
      />
    </v-overlay>
    <template v-else>
      <v-card>
        <v-card-text class="pa-0">
          <v-tabs
            v-model="tab"
            grow
          >
            <v-tab href="#fogbugz">
              FogBugz
            </v-tab>
            <v-tab href="#comments-by-category">
              Comments by Category
            </v-tab>
            <v-tab href="#comments-by-tag">
              Comments by Tag
            </v-tab>
            <v-tab href="#comments-by-user">
              Comments by User
            </v-tab>
            <v-tab href="#tag-ratings">
              Tag Ratings
            </v-tab>
            <v-tab href="#participants">
              Participants
            </v-tab>
          </v-tabs>
          <v-tabs-items
            v-model="tab"
          >
            <v-tab-item value="fogbugz">
              <v-card flat>
                <v-card-text>
                  <v-row dense>
                    <v-col
                      v-if="event.fogbugz_tag"
                      :cols="12"
                    >
                      <v-card
                        :loading="loading.fogbugzCases"
                        class="ma-0"
                      >
                        <v-card-title class="mb-2">
                          <h1 class="pa-1">
                            FogBugz Cases tagged "{{ event.fogbugz_tag }}"
                            <span v-if="fogbugzCases.length">
                              ({{ fogbugzCases.length }})
                            </span>
                          </h1>
                        </v-card-title>
                        <v-divider />
                        <v-data-table
                          v-if="!loading.fogbugzCases && fogbugzCases.length"
                          :headers="fogbugzHeaders"
                          :items="fogbugzCases"
                          :options="{
                            itemsPerPage: 100,
                          }"
                          item-key="id"
                          class="elevation-1"
                          dense
                          flat
                        >
                          <template v-slot:item.id="{ item }">
                            <a
                              :href="`https://fogbugz.unity3d.com/f/cases/${item.id}/`"
                              target="_blank"
                            >
                              {{ item.id }}
                            </a>
                          </template>
                        </v-data-table>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-tab-item>
            <v-tab-item value="comments-by-category">
              <v-card flat>
                <v-card-text>
                  <v-row dense>
                    <v-col :cols="12">
                      <v-card class="ma-0 mb-4">
                        <v-card-title class="mb-2">
                          <h1 class="pa-2">
                            Comments without category ({{ commentsWithoutCategory.length }})
                          </h1>
                        </v-card-title>
                        <v-divider />
                        <v-data-table
                          :headers="projectsCommentsHeaders"
                          :items="commentsWithoutCategory"
                          :group-by="['project.name']"
                          :options="{
                            itemsPerPage: 100,
                          }"
                          item-key="id"
                          show-expand
                          dense
                          flat
                        >
                          <template v-slot:item.user="{ item }">
                            <div class="d-flex align-center justify-start">
                              <slack-avatar
                                class="mr-2"
                                :user="item.user"
                                :size="20"
                              />
                              {{ item.user.name }}
                            </div>
                          </template>
                          <template v-slot:item.project="{ item }">
                            <router-link
                              :to="`/event/${event.slug}/${item.project.random_key}`"
                              v-text="item.project.name"
                            />
                          </template>
                          <template v-slot:item.value="{ item }">
                            <div v-line-clamp="1">
                              {{ item.value }}
                            </div>
                          </template>
                          <template v-slot:item.decisions="{ item }">
                            <div>
                              {{ item.decisions.length ? _.map(item.decisions, 'value').join('\n') : '' }}
                            </div>
                          </template>
                          <template v-slot:item.resolution="{ item }">
                            <div v-line-clamp="1">
                              {{ item.resolution ? item.resolution.title : '' }}
                            </div>
                          </template>
                          <template v-slot:expanded-item="{ headers, item }">
                            <td
                              class="pa-4"
                              :colspan="headers.length"
                            >
                              <project-comment
                                :comment="item"
                                :event="event"
                                hide-edit-button-from-normal-users
                                show-resolution
                              />
                            </td>
                          </template>
                        </v-data-table>
                      </v-card>
                    </v-col>
                    <v-col
                      v-for="(commentList, category) in commentsByCategory"
                      :key="category"
                      :cols="12"
                    >
                      <v-card class="ma-0 mb-4">
                        <v-card-title class="mb-2">
                          <h1 class="pa-1">
                            "{{ category }}" comments ({{ commentList.length }})
                          </h1>
                        </v-card-title>
                        <v-divider />
                        <v-data-table
                          :headers="projectsCommentsHeaders"
                          :items="commentList"
                          :group-by="['project.name']"
                          :options="{
                            itemsPerPage: 100,
                          }"
                          item-key="id"
                          show-expand
                          dense
                          flat
                        >
                          <template v-slot:item.user="{ item }">
                            <div class="d-flex align-center justify-start">
                              <slack-avatar
                                class="mr-2"
                                :user="item.user"
                                :size="20"
                              />
                              {{ item.user.name }}
                            </div>
                          </template>
                          <template v-slot:item.project="{ item }">
                            <router-link
                              :to="`/event/${event.slug}/${item.project.random_key}`"
                              v-text="item.project.name"
                            />
                          </template>
                          <template v-slot:item.value="{ item }">
                            <div v-line-clamp="1">
                              {{ item.value }}
                            </div>
                          </template>
                          <template v-slot:item.decisions="{ item }">
                            <div>
                              {{ item.decisions.length ? _.map(item.decisions, 'value').join('\n') : '' }}
                            </div>
                          </template>
                          <template v-slot:item.resolution="{ item }">
                            <div v-line-clamp="1">
                              {{ item.resolution ? item.resolution.title : '' }}
                            </div>
                          </template>
                          <template v-slot:expanded-item="{ headers, item }">
                            <td
                              class="pa-4"
                              :colspan="headers.length"
                            >
                              <project-comment
                                :comment="item"
                                :event="event"
                                hide-edit-button-from-normal-users
                                show-resolution
                              />
                            </td>
                          </template>
                        </v-data-table>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-tab-item>
            <v-tab-item value="comments-by-tag">
              <v-card flat>
                <v-card-text>
                  <v-row
                    class="mb-4"
                    dense
                  >
                    <v-col :cols="12">
                      <h3>Decisions</h3>
                    </v-col>
                    <v-col :cols="3">
                      <v-card
                        class="fill-height ma-0"
                        color="blue lighten-4"
                        outlined
                      >
                        <v-progress-linear :value="commentsWithDecision.length * 100 / comments.length" />
                        <v-card-title v-text="'Total'" />
                        <v-card-text>
                          <div class="mb-2">
                            {{ commentsWithDecision.length }}/{{ comments.length }} ({{ (commentsWithDecision.length * 100 / comments.length).toFixed(2) }}%)
                          </div>
                          <div
                            v-for="(decisions, value) in _(comments).map('decisions').flatten().filter(decision => decision.value).groupBy('value').value()"
                            :key="value"
                            class="caption mb-1"
                          >
                            {{ decisions.length }} x
                            <v-chip
                              outlined
                              x-small
                              label
                            >
                              {{ value }}
                            </v-chip>
                          </div>
                        </v-card-text>
                      </v-card>
                    </v-col>
                    <v-col
                      v-for="(subsetOfComments, tag) in commentsByTag"
                      :key="tag"
                      :cols="3"
                    >
                      <v-card
                        class="fill-height ma-0"
                        :color="filterLeaveOnlyWithDecision(subsetOfComments).length === subsetOfComments.length ? 'green lighten-4' : filterLeaveOnlyWithDecision(subsetOfComments).length ? 'yellow lighten-4' : 'red lighten-4'"
                        outlined
                      >
                        <v-progress-linear :value="filterLeaveOnlyWithDecision(subsetOfComments).length * 100 / subsetOfComments.length" />
                        <v-card-title>
                          <h3>
                            <router-link
                              :to="`/report/${event.slug}/tag/${_.kebabCase(tag)}`"
                              v-text="tag"
                            />
                          </h3>
                        </v-card-title>
                        <v-card-text>
                          <div class="mb-2">
                            {{ filterLeaveOnlyWithDecision(subsetOfComments).length }}/{{ subsetOfComments.length }} ({{ (filterLeaveOnlyWithDecision(subsetOfComments).length * 100 / subsetOfComments.length).toFixed(2) }}%)
                          </div>
                          <div
                            v-for="(decisions, value) in _(subsetOfComments).map('decisions').flatten().filter(decision => decision.value).groupBy('value').value()"
                            :key="value"
                            class="caption mb-1"
                          >
                            {{ decisions.length }} x
                            <v-chip
                              outlined
                              x-small
                              label
                            >
                              {{ value }}
                            </v-chip>
                          </div>
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </v-row>
                  <v-tabs
                    v-model="resolutionTab"
                    class="mb-2"
                  >
                    <v-tab>
                      Out of all comments
                    </v-tab>
                    <v-tab>
                      Out of comments with decisions
                    </v-tab>
                  </v-tabs>
                  <v-tabs-items v-model="resolutionTab">
                    <v-tab-item
                      :transition="false"
                      :reverse-transition="false"
                    >
                      <v-row
                        class="mb-4"
                        dense
                      >
                        <v-col :cols="12">
                          <h3>Resolutions / all comments</h3>
                        </v-col>
                        <v-col :cols="3">
                          <v-card
                            class="fill-height ma-0"
                            color="blue lighten-4"
                            outlined
                          >
                            <v-progress-linear :value="commentsWithResolution.length * 100 / comments.length" />
                            <v-card-title v-text="'Total'" />
                            <v-card-text>
                              <div class="mb-2">
                                {{ commentsWithResolution.length }}/{{ comments.length }} ({{ (commentsWithResolution.length * 100 / comments.length).toFixed(2) }}%)
                              </div>
                              <div
                                v-for="(resolutions, title) in _(comments).map('resolution').remove(null).groupBy('title').value()"
                                :key="title"
                                class="caption mb-1"
                              >
                                {{ resolutions.length }} ({{ (resolutions.length * 100 / comments.length).toFixed(0) }}%) x
                                <v-chip
                                  outlined
                                  x-small
                                  label
                                >
                                  {{ title }}
                                </v-chip>
                              </div>
                            </v-card-text>
                          </v-card>
                        </v-col>
                        <v-col
                          v-for="(subsetOfComments, tag) in commentsByTag"
                          :key="tag"
                          :cols="3"
                        >
                          <v-card
                            class="fill-height ma-0"
                            :color="filterLeaveOnlyWithResolution(subsetOfComments).length === subsetOfComments.length ? 'green lighten-4' : filterLeaveOnlyWithResolution(subsetOfComments).length ? 'yellow lighten-4' : 'red lighten-4'"
                            outlined
                          >
                            <v-progress-linear :value="filterLeaveOnlyWithResolution(subsetOfComments).length * 100 / subsetOfComments.length" />
                            <v-card-title>
                              <h3>
                                <router-link
                                  :to="`/report/${event.slug}/tag/${_.kebabCase(tag)}`"
                                  v-text="tag"
                                />
                              </h3>
                            </v-card-title>
                            <v-card-text>
                              <div class="mb-2">
                                {{ filterLeaveOnlyWithResolution(subsetOfComments).length }}/{{ subsetOfComments.length }} ({{ (filterLeaveOnlyWithResolution(subsetOfComments).length * 100 / subsetOfComments.length).toFixed(2) }}%)
                              </div>
                              <div
                                v-for="(resolutions, title) in _(subsetOfComments).map('resolution').remove(null).groupBy('title').value()"
                                :key="title"
                                class="caption mb-1"
                              >
                                {{ resolutions.length }} x
                                <v-chip
                                  outlined
                                  x-small
                                  label
                                >
                                  {{ title }}
                                </v-chip>
                                ({{ (resolutions.length * 100 / subsetOfComments.length).toFixed(0) }}%)
                              </div>
                            </v-card-text>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-tab-item>
                    <v-tab-item
                      :transition="false"
                      :reverse-transition="false"
                    >
                      <v-row
                        class="mb-4"
                        dense
                      >
                        <v-col :cols="12">
                          <h3>Resolutions / comments with decisions</h3>
                        </v-col>
                        <v-col :cols="3">
                          <v-card
                            class="fill-height ma-0"
                            color="blue lighten-4"
                            outlined
                          >
                            <v-progress-linear :value="commentsWithResolution.length * 100 / commentsWithDecision.length" />
                            <v-card-title v-text="'Total'" />
                            <v-card-text>
                              <div class="mb-2">
                                {{ commentsWithResolution.length }}/{{ commentsWithDecision.length }} ({{ (commentsWithResolution.length * 100 / commentsWithDecision.length).toFixed(2) }}%)
                              </div>
                              <div
                                v-for="(resolutions, title) in _(commentsWithDecision).map('resolution').remove(null).groupBy('title').value()"
                                :key="title"
                                class="caption mb-1"
                              >
                                {{ resolutions.length }} ({{ (resolutions.length * 100 / commentsWithDecision.length).toFixed(0) }}%) x
                                <v-chip
                                  outlined
                                  x-small
                                  label
                                >
                                  {{ title }}
                                </v-chip>
                              </div>
                            </v-card-text>
                          </v-card>
                        </v-col>
                        <v-col
                          v-for="(subsetOfComments, tag) in commentsWithDecisionByTag"
                          :key="tag"
                          :cols="3"
                        >
                          <v-card
                            class="fill-height ma-0"
                            :color="filterLeaveOnlyWithResolution(subsetOfComments).length === subsetOfComments.length ? 'green lighten-4' : filterLeaveOnlyWithResolution(subsetOfComments).length ? 'yellow lighten-4' : 'red lighten-4'"
                            outlined
                          >
                            <v-progress-linear :value="filterLeaveOnlyWithResolution(subsetOfComments).length * 100 / subsetOfComments.length" />
                            <v-card-title>
                              <h3>
                                <router-link
                                  :to="`/report/${event.slug}/tag/${_.kebabCase(tag)}`"
                                  v-text="tag"
                                />
                              </h3>
                            </v-card-title>
                            <v-card-text>
                              <div class="mb-2">
                                {{ filterLeaveOnlyWithResolution(subsetOfComments).length }}/{{ subsetOfComments.length }} ({{ (filterLeaveOnlyWithResolution(subsetOfComments).length * 100 / subsetOfComments.length).toFixed(2) }}%)
                              </div>
                              <div
                                v-for="(resolutions, title) in _(subsetOfComments).map('resolution').remove(null).groupBy('title').value()"
                                :key="title"
                                class="caption mb-1"
                              >
                                {{ resolutions.length }} x
                                <v-chip
                                  outlined
                                  x-small
                                  label
                                >
                                  {{ title }}
                                </v-chip>
                                ({{ (resolutions.length * 100 / subsetOfComments.length).toFixed(0) }}%)
                              </div>
                            </v-card-text>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-tab-item>
                  </v-tabs-items>
                  <v-row dense>
                    <v-col :cols="12">
                      <v-card class="ma-0 mb-4">
                        <v-card-title class="mb-2">
                          <h1 class="pa-2">
                            Comments without tags ({{ commentsWithoutTags.length }})
                          </h1>
                        </v-card-title>
                        <v-divider />
                        <v-data-table
                          :headers="projectsCommentsHeaders"
                          :items="commentsWithoutTags"
                          :group-by="['project.name']"
                          :options="{
                            itemsPerPage: 100,
                          }"
                          item-key="id"
                          show-expand
                          dense
                          flat
                        >
                          <template v-slot:item.user="{ item }">
                            <div class="d-flex align-center justify-start">
                              <slack-avatar
                                class="mr-2"
                                :user="item.user"
                                :size="20"
                              />
                              {{ item.user.name }}
                            </div>
                          </template>
                          <template v-slot:item.project="{ item }">
                            <router-link
                              :to="`/event/${event.slug}/${item.project.random_key}`"
                              v-text="item.project.name"
                            />
                          </template>
                          <template v-slot:item.value="{ item }">
                            <div v-line-clamp="1">
                              {{ item.value }}
                            </div>
                          </template>
                          <template v-slot:item.decisions="{ item }">
                            <div>
                              {{ item.decisions.length ? _.map(item.decisions, 'value').join('\n') : '' }}
                            </div>
                          </template>
                          <template v-slot:item.resolution="{ item }">
                            <div v-line-clamp="1">
                              {{ item.resolution ? item.resolution.title : '' }}
                            </div>
                          </template>
                          <template v-slot:expanded-item="{ headers, item }">
                            <td
                              class="pa-4"
                              :colspan="headers.length"
                            >
                              <project-comment
                                :comment="item"
                                :event="event"
                                hide-edit-button-from-normal-users
                                show-resolution
                              />
                            </td>
                          </template>
                        </v-data-table>
                      </v-card>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col
                      v-for="(values, key) in commentsByTag"
                      :key="key"
                      :cols="12"
                    >
                      <v-card class="ma-0 mb-4">
                        <v-card-title class="mb-2">
                          <h6 class="text-h6 pa-1">
                            Comments tagged with
                            <v-chip
                              :to="`/report/${event.slug}/tag/${_.kebabCase(key)}`"
                              color="primary"
                              outlined
                              small
                              label
                            >
                              {{ key }}
                            </v-chip>
                          </h6>
                        </v-card-title>
                        <v-divider />
                        <v-data-table
                          :headers="projectsCommentsHeaders"
                          :items="values"
                          :options="{
                            itemsPerPage: 100,
                          }"
                          item-key="id"
                          show-expand
                          dense
                          flat
                        >
                          <template v-slot:item.user="{ item }">
                            <div class="d-flex align-center justify-start">
                              <slack-avatar
                                class="mr-2"
                                :user="item.user"
                                :size="20"
                              />
                              <router-link
                                :to="`/report/${event.slug}/user/${item.user.email}`"
                                v-text="item.user.name"
                              />
                            </div>
                          </template>
                          <template v-slot:item.project="{ item }">
                            <router-link
                              :to="`/event/${event.slug}/${item.project.random_key}`"
                              v-text="item.project.name"
                            />
                          </template>
                          <template v-slot:item.value="{ item }">
                            <div v-line-clamp="1">
                              {{ item.value }}
                            </div>
                          </template>
                          <template v-slot:item.decisions="{ item }">
                            <div>
                              {{ item.decisions.length ? _.map(item.decisions, 'value').join('\n') : '' }}
                            </div>
                          </template>
                          <template v-slot:item.resolution="{ item }">
                            <div v-line-clamp="1">
                              {{ item.resolution ? item.resolution.title : '' }}
                            </div>
                          </template>
                          <template v-slot:expanded-item="{ headers, item }">
                            <td
                              class="pa-4"
                              :colspan="headers.length"
                            >
                              <project-comment
                                :comment="item"
                                :event="event"
                                hide-edit-button-from-normal-users
                                show-resolution
                              />
                            </td>
                          </template>
                        </v-data-table>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-tab-item>
            <v-tab-item value="comments-by-user">
              <div
                v-if="commentsByUser"
              >
                <v-row
                  class="pa-4"
                  dense
                >
                  <v-col :cols="6">
                    <v-select
                      v-model="filterDecisionValue"
                      label="Filter by Decision"
                      :items="_(comments).map('decisions').flatten().map('value').remove().uniq().value()"
                      clearable
                      outlined
                    />
                  </v-col>
                  <v-col :cols="6">
                    <v-select
                      v-model="filterResolutionValue"
                      label="Filter by Resolution"
                      :items="_(comments).map('resolution.title').uniq().value()"
                      clearable
                      outlined
                    />
                  </v-col>
                  <v-col :cols="12">
                    Showing {{ commentsFiltered.length }} / {{ comments.length }} comments ({{ (commentsFiltered.length * 100 / comments.length).toFixed(2) }}%)
                  </v-col>
                </v-row>
                <comments-by-user
                  v-for="(commentList, username) in commentsByUser"
                  :key="username"
                  :title="username"
                  :headers="projectsCommentsHeaders"
                  :comments="commentList"
                />
              </div>
            </v-tab-item>
            <v-tab-item value="tag-ratings">
              <v-card
                class="ma-0"
                flat
              >
                <v-card-text>
                  <v-row dense>
                    <v-col
                      v-for="(ratings, tag) in tagRatings"
                      :key="tag"
                      :cols="4"
                    >
                      <v-card
                        class="fill-height"
                        :color="!ratings.missing.length ? 'green lighten-4' : ratings.rating ? 'yellow lighten-4' : 'red lighten-4'"
                        outlined
                      >
                        <v-progress-linear :value="ratings.ratedTimes * 100 / ratings.usedTimes" />
                        <v-card-title class="d-flex align-center justify-space-between">
                          <h3 class="mr-2">
                            <router-link
                              :to="`/report/${event.slug}/tag/${_.kebabCase(tag)}`"
                              v-text="tag"
                            />
                            <span v-if="ratings.rating">
                              ({{ ratings.rating }})
                            </span>
                            <small class="caption text--disabled">
                              {{ ratings.ratedTimes }}/{{ ratings.usedTimes }}
                            </small>
                          </h3>
                          <v-rating
                            empty-icon="mdi-star-outline"
                            half-icon="mdi-star-half-full"
                            full-icon="mdi-star"
                            background-color="grey"
                            color="primary"
                            :length="5"
                            :size="16"
                            :value="parseFloat(ratings.rating)"
                            half-increments
                            readonly
                            dense
                          />
                        </v-card-title>
                        <v-card-text>
                          <div
                            v-if="ratings.missing.length"
                            class="mt-4"
                          >
                            <div class="mb-2">
                              Missing ratings
                            </div>
                            <div
                              v-for="project in ratings.missing.map(missing => missing.project)"
                              :key="project.id"
                            >
                              <router-link
                                :to="`/event/${event.slug}/${project.random_key}`"
                                v-text="project.name"
                              />
                            </div>
                          </div>
                          <div
                            v-if="ratings.rated.length"
                            class="mt-4"
                          >
                            <div class="mb-2">
                              Provided ratings
                            </div>
                            <div
                              v-for="rated in ratings.rated"
                              :key="rated.project.id"
                            >
                              <router-link
                                :to="`/event/${event.slug}/${rated.project.random_key}`"
                                v-text="rated.project.name"
                              />
                              ({{ rated.rating }})
                            </div>
                          </div>
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-tab-item>
            <v-tab-item value="participants">
              <v-card flat>
                <v-card-text>
                  <v-row dense>
                    <v-col :cols="12">
                      <v-card class="ma-0 mb-4">
                        <v-card-title class="mb-2">
                          <h1 class="pa-1">
                            Participants by Project Owner
                          </h1>
                          <v-spacer />
                          <v-text-field
                            v-model="searchParticipant"
                            label="Search"
                            hide-details
                            outlined
                          />
                        </v-card-title>
                        <v-divider />
                        <v-data-table
                          :headers="projectsParticipantsHeaders"
                          :items="participantSlots"
                          :options="{
                            itemsPerPage: 100,
                          }"
                          :group-by="['project.owner.name']"
                          :search="searchParticipant"
                          item-key="id"
                          dense
                          flat
                        >
                          <template v-slot:item.user="{ item }">
                            <div class="d-flex align-center justify-start">
                              <slack-avatar
                                class="mr-2"
                                :user="item.user"
                                :size="20"
                              />
                              {{ item.user.name }}
                            </div>
                          </template>
                          <template v-slot:item.project="{ item }">
                            <router-link
                              :to="`/event/${event.slug}/${item.project.random_key}`"
                              v-text="item.project.name"
                            />
                          </template>
                        </v-data-table>
                      </v-card>
                    </v-col>
                    <v-col :cols="12">
                      <v-card class="ma-0 mb-4">
                        <v-card-title class="mb-2">
                          <h1 class="pa-1">
                            Participants by Manager
                          </h1>
                          <v-spacer />
                          <v-text-field
                            v-model="searchParticipant"
                            label="Search"
                            hide-details
                            outlined
                          />
                        </v-card-title>
                        <v-divider />
                        <v-data-table
                          :headers="projectsParticipantsHeaders"
                          :items="participantSlots"
                          :options="{
                            itemsPerPage: 100,
                          }"
                          :group-by="['user.manager']"
                          :search="searchParticipant"
                          item-key="id"
                          dense
                          flat
                        >
                          <template v-slot:item.user="{ item }">
                            <div class="d-flex align-center justify-start">
                              <slack-avatar
                                class="mr-2"
                                :user="item.user"
                                :size="20"
                              />
                              {{ item.user.name }}
                            </div>
                          </template>
                          <template v-slot:item.project="{ item }">
                            <router-link
                              :to="`/event/${event.slug}/${item.project.random_key}`"
                              v-text="item.project.name"
                            />
                          </template>
                        </v-data-table>
                      </v-card>
                    </v-col>
                    <v-col
                      :cols="12"
                    >
                      <v-textarea
                        label="Participants"
                        :value="_.uniq(participantSlots.map(p => `@${p.user.email.replace('@unity3d.com', '')}`)).join(' ')"
                        outlined
                      />
                    </v-col>
                    <v-col
                      :cols="12"
                    >
                      <v-textarea
                        label="Participants Photos"
                        :value="_.uniq(participantSlots.map(p => `${p.user.slack_picture}`)).join('\n')"
                        outlined
                      />
                    </v-col>
                    <v-col
                      :cols="12"
                    >
                      <v-textarea
                        label="Projects"
                        :value="projectNames"
                        outlined
                      />
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>
      </v-card>
    </template>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import { getFogbugz } from '@/api'

import SlackAvatar from './components/core/SlackAvatar'
import ProjectComment from './components/core/ProjectCommentIsolated'
import CommentsByUser from './report/CommentsByUser'

export default {
  name: 'DashboardProject',
  components: { SlackAvatar, ProjectComment, CommentsByUser },
  data: () => ({
    resolutionTab: 0,
    event: null,
    projects: [],
    comments: [],
    fogbugzCases: [],
    fogbugzHeaders: [
      { text: 'ID', value: 'id' },
      { text: 'Title', value: 'title' },
      { text: 'Assigned To', value: 'assignedTo' },
      { text: 'Regression', value: 'regression' },
      { text: 'Status', value: 'status' }
    ],
    projectsCommentsHeaders: [
      { text: 'Comment Author', value: 'user', width: '250' },
      { text: 'Project', value: 'project', width: '250' },
      { text: 'Comment', value: 'value' },
      { text: 'Decisions', value: 'decisions', width: '350' },
      { text: 'Resolution', value: 'resolution', width: '200' }
    ],
    projectsParticipantsHeaders: [
      { text: 'ID', value: 'id' },
      { text: 'User', value: 'user' },
      { text: 'Project', value: 'project' }
    ],
    filterDecisionValue: null,
    filterResolutionValue: null,
    loading: {
      fogbugzCases: null
    },
    searchParticipant: null
  }),
  computed: {
    ...mapState('user', ['claims']),
    tab: {
      set (tab) {
        this.$router.replace({ query: { ...this.$route.query, tab } })
      },
      get () {
        return this.$route.query.tab
      }
    },
    participantSlots () {
      return this._(this.projects)
        .filter(project => project.is_enabled)
        .map(
          project => project.slots.map(slot => ({ ...slot, project }))
        )
        .flatten()
        .filter('user')
        .value()
    },
    projectNames () {
      return this.projects
        .filter(project => project.is_enabled)
        .map(project => `"${project.name}"`).join(' ')
    },
    commentsWithResolution () {
      return this.comments.filter(comment => comment.resolution)
    },
    commentsWithDecision () {
      return this.comments.filter(comment => comment.decisions.filter(decision => decision.value).length)
    },
    commentsWithCategory () {
      return this.comments.filter(comment => comment.category)
    },
    commentsWithoutCategory () {
      return this.comments.filter(comment => !comment.category)
    },
    commentsByCategory () {
      return this._.groupBy(this.commentsWithCategory, 'category.display_name')
    },
    commentsFiltered () {
      return this.comments
        .filter(this.filterByDecision)
        .filter(this.filterByResolution)
    },
    commentsByUser () {
      return this._(this.commentsFiltered)
        .orderBy(['user.name'], ['asc'])
        .groupBy('user.name')
        .value()
    },
    commentsByTag () {
      return this._(this.comments)
        .map('tags')
        .flatten()
        .map('tag')
        .uniqBy('name')
        .orderBy(['name'], ['asc'])
        .keyBy('display_name')
        .mapValues((value, key) => {
          return this.comments.filter(comment => comment.tags.map(tag => tag.tag.display_name).includes(key))
        })
        .value()
    },
    commentsWithoutTags () {
      return this.comments.filter(comment => !comment.tags.length)
    },
    commentsWithResolutionByTag () {
      return this._(this.commentsWithResolution)
        .map('tags')
        .flatten()
        .map('tag')
        .uniqBy('name')
        .orderBy(['name'], ['asc'])
        .keyBy('display_name')
        .mapValues((value, key) => {
          return this.commentsWithResolution.filter(comment => comment.tags.map(tag => tag.tag.display_name).includes(key))
        })
        .value()
    },
    commentsWithDecisionByTag () {
      return this._(this.commentsWithDecision)
        .map('tags')
        .flatten()
        .map('tag')
        .uniqBy('name')
        .orderBy(['name'], ['asc'])
        .keyBy('display_name')
        .mapValues((value, key) => {
          return this.commentsWithDecision.filter(comment => comment.tags.map(tag => tag.tag.display_name).includes(key))
        })
        .value()
    },
    tagRatings () {
      return this._(this.projects)
        .filter(project => project.is_enabled)
        .map(
          project => project.tags.map(tag => ({ project, ...tag }))
        )
        .flatten()
        .groupBy('tag.display_name')
        .mapValues((values, key) => {
          const ratedValues = values.filter(value => value.rating !== 0)
          const missingValues = values.filter(value => value.rating === 0)
          return {
            rating: ratedValues.length ? (ratedValues.map(value => value.rating).reduce((a, b) => a + b) / ratedValues.length).toFixed(2) : null,
            ratedTimes: ratedValues.length,
            usedTimes: values.length,
            rated: ratedValues,
            missing: missingValues
          }
        })
        .value()
    }
  },
  watch: {
    event (newValue) {
      if (newValue && newValue.fogbugz_tag) {
        this.refreshFogbugzCases(newValue.fogbugz_tag)
      }
    }
  },
  created () {
    if (this.event && this.event.fogbugz_tag) {
      this.refreshFogbugzCases(this.event.fogbugz_tag)
    }
  },
  methods: {
    async refreshFogbugzCases (tag) {
      this.loading.fogbugzCases = true
      try {
        this.fogbugzCases = await getFogbugz({ tag })
      } catch (error) {
        console.error(error)
      }
      this.loading.fogbugzCases = false
    },
    filterByDecision (comment) {
      if (!this.filterDecisionValue) return true
      return comment.decisions.map(decision => decision.value).includes(this.filterDecisionValue)
    },
    filterByResolution (comment) {
      if (this.filterResolutionValue === null) return true
      else if (this.filterResolutionValue === undefined) return !comment.resolution
      return comment.resolution && comment.resolution.title === this.filterResolutionValue
    },
    filterLeaveOnlyWithDecision (comments) {
      return comments.filter(comment => comment.decisions.filter(decision => decision.value).length)
    },
    filterLeaveOnlyWithResolution (comments) {
      return comments.filter(comment => comment.resolution)
    }
  },
  apollo: {
    event: {
      query: require('@/gql/getEvent').default,
      variables () {
        return {
          slug: this.$route.params.eventSlug
        }
      }
    },
    projects: {
      query: require('@/gql/getProjects').default,
      variables () {
        return {
          slug: this.$route.params.eventSlug,
          excludeDraft: true
        }
      }
    },
    comments: {
      query: require('@/gql/getCommentsByEvent').default,
      variables () {
        return {
          slug: this.$route.params.eventSlug
        }
      }
    }
  }
}
</script>
