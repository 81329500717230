<template>
  <div>
    <v-divider />
    <v-card
      class="ma-0"
      flat
    >
      <v-card-title class="d-flex align-center justify-space-between mb-2">
        <div class="d-flex align-center justify-start">
          <slack-avatar
            class="mr-2"
            :user="comments[0].user"
            :size="32"
          />
          <h3 class="pa-2">
            {{ title }} ({{ comments.length }})
          </h3>
        </div>
        <router-link
          :to="`/report/${$route.params.eventSlug}/user/${comments[0].user.email}`"
          v-text="'Open Report Page'"
        />
      </v-card-title>
      <v-divider />
      <v-data-table
        :headers="headers"
        :items="comments"
        :group-by="_(comments).map('project.name').uniq().value().length > 1 ? ['project.name'] : undefined"
        item-key="id"
        hide-default-footer
        disable-pagination
        show-expand
        dense
        flat
      >
        <template v-slot:item.project="{ item }">
          <router-link
            v-if="$route.params.eventSlug"
            :to="`/event/${$route.params.eventSlug}/${item.project.random_key}`"
            v-text="item.project.name"
          />
          <div
            v-else
            v-text="item.project.name"
          />
        </template>
        <template v-slot:item.value="{ item }">
          <div v-line-clamp="1">
            {{ item.value }}
          </div>
        </template>
        <template v-slot:item.decisions="{ item }">
          <div v-if="item.decisions.length">
            <div
              v-for="decision in item.decisions.filter(decision => decision.value)"
              :key="decision.id"
              class="d-flex align-center justify-start my-1"
            >
              <slack-avatar
                class="mr-2"
                :user="decision.user"
                :size="20"
              />
              {{ decision.value }}
            </div>
          </div>
        </template>
        <template v-slot:item.resolution="{ item }">
          <div
            v-if="item.resolution"
            v-line-clamp="1"
            class="success--text font-weight-bold"
          >
            {{ item.resolution.title }}
          </div>
          <div
            v-else
            v-line-clamp="1"
            class="error--text font-weight-bold"
          >
            undefined
          </div>
        </template>
        <template v-slot:expanded-item="{ headers: listOfHeaders, item }">
          <td
            class="pa-4"
            :colspan="listOfHeaders.length"
          >
            <project-comment
              :comment="item"
              :event="event"
              hide-edit-button-from-normal-users
              show-resolution
            />
          </td>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import SlackAvatar from '../components/core/SlackAvatar'
import ProjectComment from '../components/core/ProjectCommentIsolated'

export default {
  name: 'ReportCommentsByUser',
  components: { SlackAvatar, ProjectComment },
  props: {
    title: {
      type: String,
      default: () => ''
    },
    event: {
      type: Object,
      default: () => null
    },
    comments: {
      type: Array,
      default: () => ([])
    }
  },
  data: () => ({
    headers: [
      { text: 'Project', value: 'project', width: '250' },
      { text: 'Comment', value: 'value' },
      { text: 'Decisions', value: 'decisions', width: '350' },
      { text: 'Resolution', value: 'resolution', width: '250' }
    ]
  })
}
</script>
